import React, { useState, useEffect } from "react"
import Layout from "../components/layout.js";
import Image from "../components/image.js";
import * as JsSearch from "js-search"
import { Link } from "gatsby";

const SearchPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [indexFetched, setFetched] = useState(false);
  const [search, setSearchData] = useState({});
  const [results, setResults] = useState([]);

  const query = props.location?.state?.query;
  const fetchIndex = async () => {
    let index = await fetch("/index.json");
    index = await index.json();
    setFetched(true);
    return index;
  }
  const buildIndex = (index) => {
    const dataToSearch = new JsSearch.Search("path");
    dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("path")
    dataToSearch.addIndex("path")
    dataToSearch.addIndex("name")
    dataToSearch.addDocuments(index)
    setSearchData(dataToSearch);
    return dataToSearch;
  }
  const submit = async () => {
    let dataToSearch = search;
    if (!indexFetched && !loading) {
      setLoading(true);
      const index = await fetchIndex();
      dataToSearch = buildIndex(index);
      setLoading(false);
    }
    if (loading) {
      return;
    }
    try {
      const queryResult = dataToSearch.search(query);
      setResults(queryResult);
    } catch(err) {
      console.error("search failed",err);
    }
  }

  useEffect(() => {
    setResults([]);
    if (!query || !query.length) {
      return;
    }
    submit();
  }, [query]);

  return (
    <Layout infos={{
      title: "Search results"+((query && query.length) ?" for "+query:""),
      description: "Search among a wide selection of icons from cloud services providers",
      type: "search"
    }}>
    <h1>Search Results {
      (query && query.length)
      ? <>For: <strong>{ query } ({results.length})</strong></>
      : <></>
    }</h1>
    {
      (!indexFetched || loading)
      ? <></>
      : <>
        {
          (results.length === 0)
          ? <p>Sorry, no results match your search query. Try something else</p>
          : <ul id="popular">
              {
                results.map(icon => {
                  return <li key={icon.icon} >
                    <Link
                      to={ "/" + icon.path }
                    >
                      <Image src={ icon.icon } alt={ icon.name } />
                      <h2>{ icon.name }</h2>
                      <span data-provider={ icon.provider.slug }>
                        { icon.provider.name }
                      </span>
                    </Link>
                  </li> 
                })
              }
            </ul>
        }
        </>
    }
    </Layout>
  )
}

export default SearchPage
